import React, { useEffect, useState } from "react";
import { useContext, createContext, ReactNode } from "react";
import usePreferredColorSchema from "./usePreferredColorSchema";

export type Darkness = 'light' | 'dark';

const DarknessContext = createContext<Darkness>('light');

export const useDarkness = () => {
    return useContext(DarknessContext);
};

export const DarknessProvider = ({
    children,
    darkness,
  }: {
    children: ReactNode;
    darkness?: Darkness;
  }) => {
    return (
      <DarknessContext.Provider value={darkness ?? 'light'}>
        <div className={`${darkness === 'dark' ? 'dark' : ''} contents`}>
          {children}
        </div>
      </DarknessContext.Provider>
    );
  };
  