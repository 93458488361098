import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';

export type SectionHeaderProps = {
  subtitle: ReactNode;
  title: ReactNode;
};

export default function SectionHeader({ subtitle, title }: SectionHeaderProps) {
  return (
    <div className="sm:mb-8 mb-6">
      <p className="sm:text-xl text-base uppercase font-bold sm:mb-2 mb-1 text-slate-500">
        {subtitle}
      </p>
      <h2 className="sm:text-4xl text-2xl font-bold">{title}</h2>
    </div>
  );
}

export const query = graphql`
  fragment SectionHeader on STRAPI__COMPONENT_COMPONENTS_SECTION_HEADER {
    subtitle
    title
  }
`;
