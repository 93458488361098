import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import React from 'react';
import { DarknessProvider } from '../../hooks/useDarkness';
import Button, { StrapiApiButton } from '../buttons/Button';
import StrapiImage, { StrapiApiImage } from '../images/StrapiImage';

export type PageHeaderProps = {
  title: string;
  intro: string;
  addGradient: boolean;
  image: StrapiApiImage;
  actions: StrapiApiButton[];
};

export default function PageHeader({
  title,
  intro,
  image,
  addGradient,
  actions,
}: PageHeaderProps) {
  if (!title && !intro && !image) return null;
  return (
    <section
      className="relative overflow-hidden bg-black text-white flex"
      style={{ minHeight: '25rem' }}
    >
      <DarknessProvider darkness="dark">
        <StrapiImage
          className="!absolute inset-0 z-0"
          image={image}
          loading="eager"
        />
        {addGradient && (
          <div className="absolute inset-0 z-10 bg-gradient-to-r from-black to-transparent"></div>
        )}
        <div className="relative z-20 sm:py-16 py-8 sm:px-8 px-4 container m-auto flex items-center">
          <div className="max-w-prose">
            <h1 className="font-bold sm:text-5xl text-3xl mb-8">{title}</h1>
            <p className="sm:text-lg text-base">{intro}</p>
            <div className="mt-8 sm:space-x-4">
              {actions?.map((action) => (
                <Button
                  key={action.id}
                  className="sm:mb-0 mb-4 sm:inline-block block max-w-max"
                  {...action}
                >
                  {action.title}{' '}
                  <FontAwesomeIcon
                    className="sm:ml-12 ml-6"
                    icon={faArrowRight}
                  />
                </Button>
              ))}
            </div>
          </div>
        </div>
      </DarknessProvider>
    </section>
  );
}

export const query = graphql`
  fragment PageHeader on STRAPI__COMPONENT_PAGE_PAGE_HEADER {
    title
    intro
    addGradient
    image {
      ...StrapiImageFullWidth
    }
    actions {
      ...Button
    }
  }
`;
