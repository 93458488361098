import { graphql } from "gatsby";
import React, { createContext, ReactNode, useContext } from "react";
import { Darkness, DarknessProvider, useDarkness } from "../../hooks/useDarkness";
import usePreferredColorSchema from "../../hooks/usePreferredColorSchema";

export type SectionAppearance = 'light' | 'dark' | 'white';

export type SectionMeta = {
    subtitle: string,
    title: string,
    appearance: SectionAppearance
}

export type SectionProps = {
    children: ReactNode,
    appearance: SectionAppearance,
    full?: boolean
};

export type SectionContextType = {
    darkness: Darkness,
    appearance: SectionAppearance
}

const SectionContext = createContext<SectionContextType>({darkness: 'light', appearance: 'light'});

export const useSectionAppearance = () => {
    return useContext(SectionContext);
};

export default function Section({
    appearance,
    children,
    full = false
}: SectionProps) {
    let baseAppearance = 'bg-slate-100 dark:bg-slate-900 text-slate-900 dark:text-white';
    let darkness: Darkness = 'light';

    if(appearance === 'white') {
        baseAppearance = 'bg-white dark:bg-slate-800 text-slate-900 dark:text-white';
        darkness = 'light';
    } else if(appearance === 'dark') {
        baseAppearance = 'bg-slate-900 dark:bg-black text-white';
        darkness = 'dark';
    }

    const realDarkness = usePreferredColorSchema();

    return (
        <section className={baseAppearance}>
            <SectionContext.Provider value={{darkness: realDarkness, appearance}}>
                <DarknessProvider darkness={darkness}>
                    <div className={`relative ${full ? '' : 'container m-auto sm:px-8 px-4 sm:py-16 py-8'}`}>
                        {children}
                    </div>
                </DarknessProvider>
            </SectionContext.Provider>
        </section>
    );
}

export const query = graphql`
    fragment BaseSection on STRAPI__COMPONENT_COMPONENTS_SECTION_HEADER {
        appearance
    }
`;