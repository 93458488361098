import {
  faClock,
  faMapPin,
  faUserGroup,
  faLanguage,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconCard from '../../components/cards/IconCard';
import DefaultGrid from '../../components/grids/DefaultGrid';
import SectionHeader from '../../components/header/SectionHeader';
import PageHeader from '../../components/sections/PageHeader';
import Section from '../../components/sections/Section';
import Translated from '../../components/translation/Translated';

export type JobOfferPageProps = {
  data: any;
};

export function JobOfferPage({ data }: JobOfferPageProps) {
  const offer = data.allStrapiJobOffer.nodes[0];

  return (
    <>
      <PageHeader
        title={offer.title}
        intro={offer.excerpt}
        addGradient
        actions={[]}
        image={offer.image}
      />
      <Section appearance="light">
        <SectionHeader
          title={<Translated ns="JobOffer" k="introSectionTitle" />}
          subtitle={<Translated ns="JobOffer" k="introSectionSubtitle" />}
        />
        <div className="lg:columns-2 gap-16 mb-16">
          <MDXRenderer>
            {offer.childStrapiJobOfferDescriptionTextnode.childMdx.body}
          </MDXRenderer>
        </div>
        <DefaultGrid>
          <IconCard
            icon={<FontAwesomeIcon icon={faClock} />}
            title={offer.time}
            subtitle={<Translated ns="JobOffer" k="hours" />}
          />
          <IconCard
            icon={<FontAwesomeIcon icon={faMapPin} />}
            title={offer.location}
            subtitle={<Translated ns="JobOffer" k="location" />}
          />
          <IconCard
            icon={<FontAwesomeIcon icon={faUserGroup} />}
            title={offer.department}
            subtitle={<Translated ns="JobOffer" k="department" />}
          />
          <IconCard
            icon={<FontAwesomeIcon icon={faLanguage} />}
            title={offer.languages}
            subtitle={<Translated ns="JobOffer" k="languages" />}
          />
        </DefaultGrid>
      </Section>
      <Section appearance="white">
        <div className="grid lg:grid-cols-2 gap-16">
          {offer.childStrapiJobOfferTasksTextnode?.childMdx?.body && (
            <div>
              <SectionHeader
                title={<Translated ns="JobOffer" k="taskSectionTitle" />}
                subtitle={<Translated ns="JobOffer" k="taskSectionSubtitle" />}
              />
              <MDXRenderer>
                {offer.childStrapiJobOfferTasksTextnode.childMdx.body}
              </MDXRenderer>
            </div>
          )}
          {offer.childStrapiJobOfferProfileTextnode?.childMdx?.body && (
            <div>
              <SectionHeader
                title={<Translated ns="JobOffer" k="profileSectionTitle" />}
                subtitle={
                  <Translated ns="JobOffer" k="profileSectionSubtitle" />
                }
              />
              <MDXRenderer>
                {offer.childStrapiJobOfferProfileTextnode.childMdx.body}
              </MDXRenderer>
            </div>
          )}
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($id: String) {
    allStrapiJobOffer(filter: { id: { eq: $id } }) {
      nodes {
        title
        excerpt
        time
        location
        department
        languages
        image {
          ...StrapiImageConstrained
        }
        childStrapiJobOfferDescriptionTextnode {
          childMdx {
            body
          }
        }
        childStrapiJobOfferProfileTextnode {
          childMdx {
            body
          }
        }
        childStrapiJobOfferTasksTextnode {
          childMdx {
            body
          }
        }
        locale
        localizations {
          data {
            attributes {
              locale
              title
            }
          }
        }
      }
    }
  }
`;

export default JobOfferPage;
