import React, { ReactNode } from 'react';

export type DefaultGridProps = {
  children: ReactNode;
  className?: string;
  narrow?: boolean;
  custom?: boolean;
};

export default function DefaultGrid({
  children,
  className,
  narrow,
  custom,
}: DefaultGridProps) {
  return (
    <div
      className={`grid ${
        custom
          ? ''
          : narrow
          ? 'xl:grid-cols-2'
          : 'xl:grid-cols-4 md:grid-cols-2'
      } grid-cols-1 gap-8 ${className}`}
    >
      {children}
    </div>
  );
}
