import React, { ReactNode } from 'react';

export type IconCardProps = {
  title: ReactNode;
  subtitle: ReactNode;
  icon: ReactNode;
};

export function IconCard({ title, subtitle, icon }: IconCardProps) {
  return (
    <div className="w-full p-4 flex text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 shadow-xl dark:border-2 border-slate-800 rounded-xl overflow-hidden">
      <div className="flex justify-center items-center text-4xl mr-4">
        {icon}
      </div>
      <div className="grow flex flex-col justify-center">
        <div className="text-lg text-black dark:text-white font-bold">
          {title}
        </div>
        {subtitle && (
          <div className="text-slate-500 dark:text-slate-500 font-medium">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
}

export default IconCard;
